import React, { useEffect, useState } from 'react'
import AutoComplete from 'react-google-autocomplete';
import styled from "styled-components";
import axios from 'axios';

import EventDataService from '../../services/event.service';
import DistanceDataService from '../../services/distance.service';
import QuoteDataService from '../../services/quote.service';

const API_KEY = "AIzaSyD56GlQxukPsngX3LA62FuFu6W9bHHt_lw";

const Styles = styled.div`
 padding: 20px;

 form {
   background: white;
   border: 1px solid #dedede;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   margin: 0 auto;
   max-width: 500px;
   padding: 30px 50px;
 }

 .autocomplete {
    background: white;
    border: 1px solid #dedede;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center,
    margin: 10px auto;
    max-width: 500px;
    padding: 30px 25px;
 }

 input {
   border: 1px solid #d9d9d9;
   border-radius: 4px;
   box-sizing: border-box;
   padding: 10px;
   width: 100%;
 }

 label {
   color: #3d3d3d;
   display: block;
   font-family: sans-serif;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 5px;
   margin-top: 5px;
 }

 .error {
   color: red;
   font-family: sans-serif;
   font-size: 12px;
   height: 30px;
 }

 .submitButton {
   background-color: #ff5c5c;
   color: white;
   font-family: sans-serif;
   font-size: 14px;
   margin: 20px 0px;
`;

const EventSubmission = () => {

    const [eventLocation, setEventLocation] = useState({});
    const [eventInfo, setEventInfo] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [eventURL, setEventURL] = useState('');

    const updateAddress = async (place, autocomplete) => {

        const lat = place.geometry.location.lat();
        const lon = place.geometry.location.lng()

        var config = {
            method: 'get',
            url: `https://maps.googleapis.com/maps/api/timezone/json?location=${lat}%2C${lon}&timestamp=1331161200&key=${API_KEY}`,
            headers: { }
        };
            
        const timezone = await axios(config).then((response) => {
            return response.data["timeZoneId"];
        }, (error) => {
            console.error(error);
        });

        var street_number = '';
        var address = '';
        var city = '';
        var state = '';
        var zipcode = '';
        var country = '';
        for (const component of place["address_components"]) {
            if (component["types"].includes("street_number")) {
                street_number = component["long_name"]
            } else if (component["types"].includes("route")) {
                address = component["long_name"]
            } else if (component["types"].includes("locality")) {
                city = component["long_name"]
            } else if (component["types"].includes("administrative_area_level_1")) {
                state = component["short_name"]
            } else if (component["types"].includes("country")) {
                country = component["short_name"]
            } else if (component["types"].includes("postal_code")) {
                zipcode = component["long_name"]
            } 
        }
        
        setEventLocation({
            event_location_name: autocomplete["gm_accessors_"]["place"]["Kj"]["formattedPrediction"].split(",")[0],
            address: street_number + " " + address,
            city: city,
            timezone: timezone,
            state: state,
            country: country,
            zipcode: zipcode,
            lat: lat,
            lon: lon
        });
    }

    const handleChange = (event) => {
        const id = event.target.id;
        const value = event.target.value;
        setEventInfo(values => ({...values, [id]: value}))
      }

    const handleSubmit = (event) => {
        
        event.preventDefault();

        setLoading(true);
        setLoadingMessage('Adding event location...');

        eventInfo["event_location_name"] = eventLocation["event_location_name"];
        eventInfo["address"] = eventLocation["address"];
        eventInfo["start_date"] = new Date(eventInfo["start_date"]).toISOString();
        eventInfo["end_date"] = new Date(eventInfo["end_date"]).toISOString();

        EventDataService.addEventLocation(eventLocation).then((addLocationResponse) => {

            EventDataService.getEventLocation(eventInfo["event_location_name"], eventInfo["address"]).then((getLocationResponse) => {

                const event_location_id = getLocationResponse["event_location_id"]
                setLoadingMessage('Calculating distances...');

                DistanceDataService.addDistances(event_location_id).then((addDistancesResponse) => {

                    setLoadingMessage('Adding event...');

                    EventDataService.addEvent(eventInfo).then((addEventResponse) => {

                        EventDataService.getEvent(eventInfo["event_name"]).then((getEventResponse) => {
                        
                            const event_id = getEventResponse["event_id"];

                            setLoadingMessage('Getting quotes for the event...');

                            QuoteDataService.addQuotes(event_id).then((response) => {

                                setLoading(false);
                                setEventURL("https://get-openspace.com/events/" + eventInfo["event_name"].replace(" ", "%20"))

                            })
                        })
                    })
                })
            })
        })
        setSubmitted(true);
    }

    useEffect(() => {

    });

    return (
        <>
        <Styles>
            {loading ?
            <div className='autocomplete'>
                <p>{loadingMessage}</p>
            </div> 
            : (
                submitted ?  (
                    <div className='autocomplete'>
                        <p>Successfully submitted! Find the event page</p><a href={eventURL}>here</a>
                    </div> 
                )
                : (
                    <div>
                    <div className='autocomplete'>
                        <div>
                            <p>Search Location: </p>
                        </div>
                        <div>
                            <AutoComplete
                            apiKey={API_KEY}
                            onPlaceSelected={(place, inputRef, autocomplete) => updateAddress(place, autocomplete)}
                            options={{
                                types: ["establishment"],
                                componentRestrictions: { country: "us" },
                            }}
                            // style={{ width: "50%", justifyContent: "center" }}
                            />
                        </div>
                    </div>
                    <div>
                        <form onSubmit={handleSubmit}>
                            <label for="event_name" >Event Name:</label>
                            <input type="text" id="event_name" onChange={handleChange} required />
                            <label for="picture_url" >Picture URL:</label>
                            <input type="text" id="picture_url" onChange={handleChange} required />
                            <label for="event_url" >Event URL:</label>
                            <input type="text" id="event_url" onChange={handleChange} required />
                            <label for="start_date" >Start Datetime (In Central Time):</label>
                            <input type="datetime-local" id="start_date" onChange={handleChange} required />
                            <label for="end_date" >End Datetime (In Central Time):</label>
                            <input type="datetime-local" id="end_date" onChange={handleChange} required />
                            <label for="event_location_name" >Location Name:</label>
                            <input disabled type="text" id="event_location_name" value={eventLocation.event_location_name} required />
                            <label for="address" >Street Address:</label>
                            <input disabled type="text" id="address" value={eventLocation.address} required />
                            <label for="city" >City:</label>
                            <input disabled type="text" id="city" value={eventLocation.city} required />
                            <label for="timezone" >Timezone:</label>
                            <input disabled type="text" id="timezone" value={eventLocation.timezone} required />
                            <label for="state" >State:</label>
                            <input disabled type="text" id="state" value={eventLocation.state} required />
                            <label for="zipcode" >Zipcode:</label>
                            <input disabled type="text" id="zipcode" value={eventLocation.zipcode} required />
                            <label for="country" >Country:</label>
                            <input disabled type="text" id="country" value={eventLocation.country} required />
                            <label for="lat" >Latitude:</label>
                            <input disabled type="text" id="lat" value={eventLocation.lat} required />
                            <label for="lon" >Longitude:</label>
                            <input disabled type="text" id="lon" value={eventLocation.lon} required />
                            <input type="submit" className="submitButton"/>
                        </form>
                    </div>
                </div>
                )
            )
        }
        </Styles>
        </>
    );
}

export default EventSubmission;