import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Logo from "../../assets/OpenSpace-removebg-preview.png"

function Header() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const hideNavbar = () => {
    navRef.current.classList.remove("responsive_nav")
  }

  return (
    <header>
      <h1><img src={Logo} alt="bug" height={100} /></h1>
      <nav ref={navRef}>
        <Link to='/event/submit' onClick={hideNavbar}>
              Submit An Event
        </Link>
        <button
          className="nav-btn nav-close-btn"
          onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>
      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
}

export default Header;