import axios from "axios";
 

class EventDataService {

  constructor() {
    this.base_url = "https://open-space-event-isab5mq5ka-uc.a.run.app";
  }

  async getEventLocation(event_location_name, address) {
    const response = await axios.get(`${this.base_url}/events/location?event_location_name=${event_location_name}&address=${address}`);
    return response.data;
  }

  async getEvent(event_name) {
    const response = await axios.get(`${this.base_url}/events/event_name/${event_name}`);
    return response.data;
  }

  async addEventLocation(event_location) {
    const response = await axios.post(`${this.base_url}/events/location`, event_location)
    return response.data;
  }

  async addEvent(event) {
    const response = await axios.post(`${this.base_url}/events`, event)
    return response.data
  }

}

export default new EventDataService();
