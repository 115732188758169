import Header from "./components/Header/index";
import Footer from "./components/Footer/index"
import EventSubmission from "./pages/EventSubmission";
import { BrowserRouter, Routes, Route } from 'react-router-dom';


const App = () => {
  return (
    <BrowserRouter>
    <Header />
    <Routes>
      <Route path="/event/submit" element={ <EventSubmission /> } />
    </Routes>
    <Footer />
    </BrowserRouter>
  )
};



export default App;