import axios from "axios";
 
class QuotesDataService {

  constructor() {
    this.base_url = "https://open-space-quote-isab5mq5ka-uc.a.run.app";
  }
 
  async addQuotes(event_id) {
    const response = await axios.post(`${this.base_url}/quotes/event_id/${event_id}`)
    return response.data
  }

}

export default new QuotesDataService();