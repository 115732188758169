import React from 'react';
import { Card } from "antd"

const Footer = () => {
  return (
    <div className='footer'>
      <Card
        style={{ margin: 'auto', marginLeft: "30%", marginRight: "30%", marginTop: 120, alignContent: 'center', textAlign: 'center', color: '#61DD8B', marginBottom: '15px' }}
      >
        Powered by <strong><a style={{ color: "#61DD8B", fontWeight: '500' }} href={"http://www.parksterai.com"} rel='noopener' target={'_blank'} >Parkster.ai</a></strong>
      </Card>
    </div>
  )
}

export default Footer