import axios from "axios";
 
class DistanceDataService {

  constructor() {
    this.base_url = "https://open-space-distance-isab5mq5ka-uc.a.run.app";
  }
 
  async addDistances(event_location_id) {
    const response = await axios.get(`${this.base_url}/distances/add/event_location_id/${event_location_id}`)
    return response.data
  }

}

export default new DistanceDataService();